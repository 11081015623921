import {
  Container,
  Row,
  Column,
  Text,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { trigger } from '@smu-chile/pkg-unimarc-hooks'

export const ToastBodyIndexing = (): React.ReactElement => {
  const handleOnClick = () => {
    trigger({ eventType: 'showMoreIndexingMemerbership', data: { show: true } })
  }

  return (
    <Container>
      <Row alignItems='center'>
        <Column>
          <Text
            customColor={getGlobalStyle('--color-primary-membership')}
            fontSize='sm'
            fontWeight='semibold'
            type='span'
          >
            💎 ¡Estamos preparando tus descuentos Diamante!
          </Text>
          <Spacer.Horizontal size={4} />
          <Row>
            <Text
              customColor={getGlobalStyle('--color-primary-membership')}
              fontSize='sm'
              fontWeight='regular'
              type='span'
            >
              Pronto podrás disfrutar de ellos.&nbsp;
            </Text>
            <Container
              maxWidth='max-content'
              onClick={handleOnClick}
            >
              <Text
                clickable='pointer'
                customColor={getGlobalStyle('--color-primary-membership')}
                fontSize='sm'
                fontWeight='medium'
                type='span'
                underline='always'
              >
                Saber más
              </Text>
            </Container>
          </Row>
        </Column>
      </Row>
    </Container>
  )
}
