import { useEffect, useState } from 'react'
import {
  Cookies,
  getCurrentDomain,
  useNewCartUnimarc
} from '@smu-chile/pkg-unimarc-hooks'

export interface IUseIndexingMembershipData {
  showIndexingMembership: boolean
  showToast: boolean
  handleCloseToast: () => void
}

/**
 * Custom hook to manage the state and behavior of "indexing membership" in a React application.
 *
 * @returns {IUseIndexingMembershipData} Object containing the state and functions for handling "indexing membership":
 * - `showIndexingMembership` (boolean): Indicates whether the indexing membership is active.
 * - `showToast` (boolean): Indicates whether to show a toast notification for indexing membership updates.
 * - `handleCloseToast` (function): Function to close the toast notification and remove the associated cookie.
 *
 * @example
 * import { useIndexingMembership } from './hooks/useIndexingMembership';
 *
 * const MyComponent = () => {
 *   const { showIndexingMembership, showToast, handleCloseToast } = useIndexingMembership();
 *
 *   return (
 *     <div>
 *       {showIndexingMembership && <p>Indexing Membership is active!</p>}
 *       {showToast && (
 *         <div className="toast">
 *           <p>Indexing Membership has changed!</p>
 *           <button onClick={handleCloseToast}>Close</button>
 *         </div>
 *       )}
 *     </div>
 *   );
 * };
 */
export const useIndexingMembership = (): IUseIndexingMembershipData => {
  const domain = getCurrentDomain()

  const [showIndexingMembership, setShowIndexingMembership] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const isIndexingCookie = JSON.parse(
    Cookies.get('indexingMembership') || 'false'
  )

  const { data, isFetching, isLoading } = useNewCartUnimarc()

  const handleCloseToast = () => {
    setShowToast(false)
    Cookies.remove('indexingMembership', {
      domain,
      path: '/'
    })
  }

  useEffect(() => {
    setShowIndexingMembership(data?.indexingMembership ?? false)
    if (data?.indexingMembership) {
      Cookies.set(
        'indexingMembership',
        JSON.stringify({ indexingMembership: data?.indexingMembership }),
        {
          domain
        }
      )
    }
  }, [data, isFetching])

  // Validate if indexingMembership was true to show a toast
  useEffect(() => {
    if (
      !isLoading &&
      data &&
      isIndexingCookie?.indexingMembership &&
      !data?.indexingMembership
    ) {
      setShowToast(true)
    }
  }, [isIndexingCookie?.indexingMembership, data, isLoading, isFetching])

  return {
    showIndexingMembership,
    showToast,
    handleCloseToast
  }
}
