import { useState } from 'react'
import { useRouter } from 'next/router'
import {
  isValidArrayWithData,
  sleep,
  useContentful,
  useList,
  useMobile,
  useSession,
  useSessionState
} from '@smu-chile/pkg-unimarc-hooks'
import { postNameToList } from '@smu-chile/pkg-unimarc-hooks/services'
import {
  IProductInList,
  ISelectedList
} from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { v4 as uuidv4 } from 'uuid'
import { EMPTY_ORANGE_IMG } from 'shared/utils/constants'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { getCategoryFromProducts } from 'shared/helpers'

/**
 * Manage properties like open modal, identify if is a new list, show tooltip, save list's name
 * and save the item to be added to the list. Also show login's modal if the user doesn't has an active session
 * also return values wich are required by de AddToListModal component
 * call the POST API for creating new list or add product into a list
 * @returns createListButton,
    innerWidth
    isLoggedIn
    listData
    notificationAddToList
    openModalCreateList
    selectedListName
    handleAddProductsToList
    handleCreateNewList
    handleOnClosed
    handleOpenMyListsModal
    setCreateListButton
    setNotificationAddToList
    setOpenModalCreateList
 */
export const useAddProductToList = (dataCart: ICart) => {
  const router = useRouter()
  const { isLoggedIn } = useSession()
  const { innerWidth } = useMobile()
  const categories = getCategoryFromProducts(dataCart)
  const products = isValidArrayWithData(categories?.items)
    ? categories.items
    : null

  const { data: list, refetch: refetchUseList } = useList({
    order: 1,
    page: 1,
    perPage: 15
  })

  const emptyOrangeImg = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-orange-img',
    options: {
      'sys.id': EMPTY_ORANGE_IMG[0]
    },
    type: 'assets'
  })

  const emptyOrangeImage =
    emptyOrangeImg?.data?.['items'][0]?.fields?.file?.url || ''

  const [openModalCreateList, setOpenModalCreateList] = useState(false)
  const [createListButton, setCreateListButton] = useState(false)
  const [notificationAddToList, setNotificationAddToList] = useState(false)
  const [selectedListName, setSelectedListName] = useSessionState(
    'selectedListName',
    ''
  )
  const [itemsToAdd, setItemsToAdd] = useState<IProductInList[]>()

  const handleOpenMyListsModal = () => {
    const url = new URL(window.location.href)
    if (!isLoggedIn) {
      url.searchParams.append('login', 'true')
      router.push(
        {
          pathname: url.pathname.toString(),
          query: url.searchParams.toString()
        },
        undefined,
        {
          shallow: true
        }
      )
    } else {
      let productsWithIssues: IProductInList[] = []
      let productsToAdd: IProductInList[] = []
      if (isValidArrayWithData(products)) {
        productsToAdd = products.map((item) => {
          return {
            brandName: item?.description?.brandName,
            id: item?.description?.productId,
            image: item?.description?.image,
            measurenmentUnit: item?.description?.measurementUnit,
            name: item?.description?.name,
            netContent: item?.description?.netContent,
            quantity: item?.price?.quantity,
            unitMultiplier: item?.price?.unitMultiplier
          }
        })
      }
      if (isValidArrayWithData(dataCart?.productsIssues?.[0]?.itemsIssues)) {
        productsWithIssues = dataCart?.productsIssues?.[0]?.itemsIssues.map(
          (product) => {
            return {
              brandName: product?.orderItem?.brandName,
              id: product?.orderItem?.productId,
              image: product?.orderItem?.image,
              measurenmentUnit: product?.orderItem?.measurementUnit,
              name: product?.orderItem?.name,
              netContent: product?.orderItem?.netContent,
              quantity: product?.orderItem?.originalQuantity,
              unitMultiplier: product?.orderItem?.unitMultiplier
            }
          }
        )
      }
      setItemsToAdd([...productsToAdd, ...productsWithIssues])
      setOpenModalCreateList(true)
    }
  }

  const handleAddProductsToList = async (selected: ISelectedList) => {
    setSelectedListName(selected?.name)
    await postNameToList({
      name: selected?.name,
      id: selected?.id,
      products: itemsToAdd
    })
    await refetchUseList()
    sleep(500)
    setOpenModalCreateList(false)
    setNotificationAddToList(true)
  }

  const handleCreateNewList = async (value: string) => {
    const listId = uuidv4()
    setSelectedListName(value)
    await postNameToList({ name: value, id: listId, products: itemsToAdd })
    await refetchUseList()
    sleep(500)
    setOpenModalCreateList(false)
    setNotificationAddToList(true)
  }

  const handleOnClosed = () => {
    setNotificationAddToList(false)
  }

  return {
    createListButton,
    emptyOrangeImage,
    innerWidth,
    isLoggedIn,
    listData: list?.data,
    notificationAddToList,
    openModalCreateList,
    selectedListName,
    handleAddProductsToList,
    handleCreateNewList,
    handleOnClosed,
    handleOpenMyListsModal,
    setCreateListButton,
    setNotificationAddToList,
    setOpenModalCreateList
  }
}
