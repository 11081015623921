import { IAvailableWindows } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'
/**
 * Retrieves start and end dates for a specific day.
 * @param {IAvailableWindows[]} availableWindows - An array of available windows.
 * @param {string} selectedDay - The day selected earlier.
 * @returns {Array<{startDate: string, endDate: string}>} - An array of objects containing start and end dates for the selected day.
 * @example
 * const availableWindows = [
 *   { startDateUtc: "2024-02-29T15:00:00+00:00", endDateUtc: "2024-02-29T17:00:59+00:00", price: 2990 },
 *   { startDateUtc: "2024-02-29T17:00:00+00:00", endDateUtc: "2024-02-29T19:00:59+00:00", price: 2990 },
 * ];
 * const selectedDay = "2024-02-29T15:00:00";
 * const datesForSelectedDay = getTimesForSelectedDay(availableWindows, selectedDay);
 * Output: [{ startDate: "2024-02-29T15:00:00", endDate: "2024-02-29T17:00:59" }, { startDate: "2024-02-29T17:00:00", endDate: "2024-02-29T19:00:59" }]
 */
export const getTimesForSelectedDay = (
  availableWindows: IAvailableWindows[],
  selectedDay: string
): IAvailableWindows[] => {
  const scheduleAvailable: IAvailableWindows[] = []
  const dateString = selectedDay.split('T')[0]
  availableWindows.forEach((window) => {
    if (window?.startDateUtc?.startsWith(dateString)) {
      scheduleAvailable.push({
        startDateUtc: window?.startDateUtc,
        endDateUtc: window?.endDateUtc,
        price: window?.price,
        id: window?.id
      })
    }
  })
  return scheduleAvailable
}
