import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import {
  Cookies,
  datalayerSimpleEvent,
  getCurrentDomain,
  patchUtmSource,
  trigger,
  useNewCartUnimarc
} from '@smu-chile/pkg-unimarc-hooks'
import { ModalDesktop } from './ModalDesktop'
import { ModalMobile } from './ModalMobile'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'

interface MyUsualsAndFavoritesWrapperProps {
  favorites: IUsualsAndFavoritesData
  isLoadingMyUsualsAndFavorites: boolean
  isLoadingOrderForm: boolean
  myUsuals: IUsualsAndFavoritesData
  orderformData: Data
  handleClose: () => void
}

export const MyUsualsAndFavoritesWrapper = ({
  favorites,
  isLoadingMyUsualsAndFavorites,
  isLoadingOrderForm,
  myUsuals,
  orderformData,
  handleClose
}: MyUsualsAndFavoritesWrapperProps): React.ReactElement => {
  const router = useRouter()
  const domain = getCurrentDomain()
  const wasLoadingOrderForm = useRef(false)
  const { refetch } = useNewCartUnimarc()
  const contentRef = useRef(null)

  const handleCloseModal = () => {
    trigger({ eventType: 'myUsuals', data: { show: false } })
    handleClose()
  }

  const utmSourceValidate = async (data: Data) => {
    if (
      !data?.marketingData?.utmSource ||
      !data?.marketingData ||
      data?.marketingData?.utmSource === 'unimarc-app'
    ) {
      await patchUtmSource('unimarc-web')
    }
  }

  const handleClickButton = () => {
    utmSourceValidate(orderformData)
    datalayerSimpleEvent({
      event: 'interacciones carro de compras',
      eventAction: 'clic',
      eventCategory: 'modal no te olvides',
      eventLabel: 'boton ir a pagar'
    })
    Cookies.remove('eventSent', {
      domain,
      path: '/'
    })
    router.push('/MyShipments')
  }

  const scrollToTopOnContent = () => {
    if (contentRef?.current) {
      contentRef.current.scrollTop = 0
    }
  }

  useEffect(() => {
    datalayerSimpleEvent({
      event: 'interacciones carro de compras',
      eventAction: 'view',
      eventCategory: 'modal no te olvides',
      eventLabel: 'carga modal no te olvides'
    })
  }, [])

  useEffect(() => {
    if (!isLoadingOrderForm && wasLoadingOrderForm.current) {
      refetch()
    }

    wasLoadingOrderForm.current = isLoadingOrderForm
  }, [isLoadingOrderForm])

  return (
    <>
      <BigScreen>
        <ModalDesktop
          contentRef={contentRef}
          favorites={favorites}
          handleClick={handleClickButton}
          handleClose={handleCloseModal}
          isLoadingMyUsualsAndFavorites={isLoadingMyUsualsAndFavorites}
          isLoadingOrderForm={isLoadingOrderForm}
          myUsuals={myUsuals}
          scrollToTopOnContent={scrollToTopOnContent}
        />
      </BigScreen>
      <SmallScreen>
        <ModalMobile
          contentRef={contentRef}
          favorites={favorites}
          handleClick={handleClickButton}
          handleClose={handleCloseModal}
          isLoadingMyUsualsAndFavorites={isLoadingMyUsualsAndFavorites}
          isLoadingOrderForm={isLoadingOrderForm}
          myUsuals={myUsuals}
          scrollToTopOnContent={scrollToTopOnContent}
        />
      </SmallScreen>
    </>
  )
}
