import {
  IAvailableSlas,
  IAvailableWindows
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'

/**
 * Matches available windows with corresponding SLA IDs, prioritizing "Despacho a Domicilio"
 *
 * @param {IAvailableWindows[]} availableWindows - List of available delivery windows
 * @param {IAvailableSlas} availableSlas - List of available SLAs
 * @returns {IAvailableWindows[]} Windows with matched SLA IDs
 *
 * @example
 * const windows = [
 *   { startDateUtc: '2025-01-23T11:30:00+00:00', price: 2990 }
 * ];
 *
 * const slas = [
 *   {
 *     id: 'Despacho a Domicilio',
 *     availableDeliveryWindows: [
 *       { startDateUtc: '2025-01-23T11:30:00+00:00' }
 *     ]
 *   }
 * ];
 *
 * const result = matchWindowsToSla(windows, slas);
 * // result: [{ startDateUtc: '2025-01-23T11:30:00+00:00', price: 2990, id: 'Despacho a Domicilio' }]
 */
export const matchWindowsToSla = (
  availableWindows: IAvailableWindows[],
  availableSlas: IAvailableSlas
): IAvailableWindows[] => {
  return availableWindows.map((window) => {
    // First, prioritize "Despacho a Domicilio"
    const despachoSla = availableSlas.find((sla) => {
      return (
        sla.id === 'Despacho a Domicilio' &&
        sla.availableDeliveryWindows.some((dw) => {
          return dw.startDateUtc === window.startDateUtc
        })
      )
    })

    if (despachoSla) {
      return { ...window, id: despachoSla?.id }
    }

    // If no "Despacho a Domicilio", find first matching SLA
    const matchingSla = availableSlas.find((sla) => {
      return sla.availableDeliveryWindows.some((dw) => {
        return dw.startDateUtc === window.startDateUtc
      })
    })

    return {
      ...window,
      id: matchingSla?.id
    }
  })
}
