import React from 'react'
import { Container, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  isValidArrayWithData,
  useContentful,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { IUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { ShelfContent } from '../ShelfContent'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  CAMPAING_APP_CHIPS_UNIMARC,
  REACT_QUERY_GENERAL
} from 'shared/utils/constants'
import { promotionsOfShelves } from 'shared/helpers/promotionsOfShelves'

interface ModalBodyShelvesProps {
  data: IUsualsAndFavoritesData
  isLoadingOrderForm: boolean
  isMobile?: boolean
  handleShowToast: (value: boolean) => void
}

export const ModalBodyShelves = ({
  data,
  isLoadingOrderForm,
  isMobile,
  handleShowToast
}: ModalBodyShelvesProps): React.ReactElement => {
  const getProducts = () => {
    return data?.products?.map((product) => {
      return { ...product?.item, ...product?.price, quantity: 0 }
    })
  }

  const { handleAdd, handleRemove, handleOnClick } = useUpdateFromShelf({
    products: getProducts(),
    tagProductQuantityChange: true,
    isOrderFormLoading: isLoadingOrderForm,
    getListId: () => {
      return 'modal no te olvides'
    },
    getListName: () => {
      return 'modal no te olvides'
    }
  })

  const resultPromotions = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE,
      'sys.id[in]': CAMPAING_APP_CHIPS_UNIMARC
    },
    reactQuery: REACT_QUERY_GENERAL
  })

  // clean the object of promotions
  const dataPromotions =
    promotionsOfShelves({ data: resultPromotions?.data }) ?? []

  return (
    <Container
      gap={isMobile ? 0 : 8}
      id={getBemId('myUsualsAndFavoritesBody', 'shelves')}
      isWrap
    >
      {isValidArrayWithData(data?.products) &&
        data.products.map((product, index) => {
          return (
            <React.Fragment key={product?.item?.itemId}>
              <ShelfContent
                dataPromotions={dataPromotions}
                handleAdd={handleAdd}
                handleOnClick={handleOnClick}
                handleRemove={handleRemove}
                handleShowToast={handleShowToast}
                indexItem={index}
                isLoadingOrderForm={isLoadingOrderForm}
                isMobile={isMobile}
                product={product}
              />
            </React.Fragment>
          )
        })}
      <Spacer.Horizontal customSize={16} />
    </Container>
  )
}
